import { TEST_TYPES } from 'common';
import emptySplitApi from '..';

export const newAssessmentApi = emptySplitApi.injectEndpoints({
  endpoints: (build) => ({
    createQuestion: build.mutation({
      query: (params) => ({
        url: `/courses/${params.id}/sections/test/${params.chapterId}/question`,
        method: 'POST',
        body: params.result,
      }),
      invalidatesTags: ['assessmentList', 'volume'],
    }),

    createAnswer: build.mutation({
      query: (params) => ({
        url: `/courses/${params.id}/sections/test/${params.chapterId}/answer`,
        method: 'POST',
        body: params.result,
      }),
      invalidatesTags: ['assessmentList', 'volume', 'learning-community'],
    }),

    getQuestionsList: build.query({
      query: (params) => ({
        url: `/courses/${params.courseId}/sections/test/${params.chapterId}/question`,
        method: 'GET',
        params,
      }),
      providesTags: ['assessmentList'],
    }),

    getAllQuestionsList: build.query({
      query: (params) => ({
        url: `/courses/${params.courseId}/sections/summary`,
        method: 'GET',
        params,
      }),
      transformResponse: (result) =>
        result.map((el) => ({
          questions: el?.questions,
          answeredTests: el?.answerData,
          chapterId: el?.questions[0]?.id,
          chapterName: el?.chapterName,
        })),
    }),

    getQuestion: build.query({
      query: (params) => ({
        url: `/courses/${params.courseId}/sections/test/${params.chapterId}/question/${params.questionId}`,
        method: 'GET',
      }),
      transformResponse: (result) => {
        if (Number(result.type) === Number(TEST_TYPES.types.TestsScaledResponseScored)) {
          const { list, feedback } = result.answer;

          const formateList = list.map((el) => ({ key: el.group, value: el.answer, id: el.id }));
          const formateData = {
            ...result,
            answer: [
              ...formateList,
              { key: 'top', value: feedback.top.answer, id: feedback.top.id },
              { key: 'bottom', value: feedback.bottom.answer, id: feedback.bottom.id },
            ],
          };
          return formateData;
        }

        return result;
      },
    }),

    updateQuestion: build.mutation({
      query: (params) => ({
        url: `/courses/${params.courseId}/sections/test/${params.chapterId}/question/${params.questionId}`,
        method: 'PUT',
        body: params.body,
      }),
      invalidatesTags: ['assessmentList'],
    }),

    deleteQuestion: build.mutation({
      query: (params) => ({
        url: `/courses/${params.courseId}/sections/test/${params.chapterId}/question/${params.questionId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['assessmentList'],
    }),
  }),
  overrideExisting: false,
});

export const {
  useCreateQuestionMutation,
  useCreateAnswerMutation,
  useLazyGetQuestionsListQuery,
  useGetAllQuestionsListQuery,
  useGetQuestionQuery,
  useUpdateQuestionMutation,
  useDeleteQuestionMutation,
} = newAssessmentApi;
