import { createSlice } from '@reduxjs/toolkit';

const initialState = { hasChange: false };

const entitiesUpdate = createSlice({
  name: 'entitiesUpdate',
  initialState,
  reducers: {
    hasEntityChange(state, action) {
      const { payload } = action;
      // eslint-disable-next-line no-param-reassign
      state.hasChange = payload;
    },
  },
});

export const { hasEntityChange } = entitiesUpdate.actions;
export default entitiesUpdate.reducer;
