import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import classNames from 'classnames';
import EXPLORE_OPTIONS from 'constants/DropdownOptions/explore';
import PATHS from 'constants/paths';
import useIsLimitedUser from 'store/services/helperHooks/useIsLimitedUser';
import Button from 'style-guide/Button';
import DropdownV2 from 'style-guide/DropdownV2';
import { Down } from 'style-guide/Icons';
import { navigateEToExploreEntity } from 'views/NewExplore/helpers';

const EntitiesButtons = ({ className }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const isLimitedUser = useIsLimitedUser();

  const isAuthUser = useSelector((state) => state.authorizationsSlice.isAuth);
  const dropdownOptions = EXPLORE_OPTIONS.filter((el) => el.value !== 'favorites');
  const limitedUserOptions = dropdownOptions.filter((el) => el.value !== 'members');

  return (
    <>
      <DropdownV2
        onClickItem={(value) => {
          const url = navigateEToExploreEntity(value);
          if (pathname !== url) {
            navigate(url);
          }
        }}
        options={isLimitedUser ? limitedUserOptions : dropdownOptions}
        space={10}
      >
        <Button
          variant='text'
          suffix={<Down className='down' width={14} height={16} />}
          className={classNames('explore ml--16', {
            activeDropdown: pathname === PATHS.EXPLORE,
          })}
        >
          Explore
        </Button>
      </DropdownV2>
      {isAuthUser ? (
        <>
          <Button
            to={PATHS.LEARNING_COMMUNITIES_MEMBER_DASHBOARD}
            variant='text'
            className={classNames('header-button ml--8', className)}
          >
            Member
          </Button>
          {!isLimitedUser ? (
            <Button
              to={PATHS.LEARNING_COMMUNITIES_DASHBOARD}
              variant='text'
              className={classNames('header-button ml--8', className)}
              disabled={isLimitedUser}
            >
              Organizer
            </Button>
          ) : null}

          {/* ToDo: remove Media Library comment after adding functionality */}

          {/* <Button variant='text' className='header-button ml--8' disabled>
            Media library
          </Button> */}
        </>
      ) : null}
    </>
  );
};

export default EntitiesButtons;
