import DOMAIN_KEYS from './domain-keys';

const { csm, workvibes, vaco } = DOMAIN_KEYS;

const ignoreAccessCodePrice = (domainKey) => [csm, vaco, workvibes].includes(domainKey);

const getAccessCodePrice = (domainKey, entityAccessCodesPrice, domainAccessCodesPrice) => {
  if (ignoreAccessCodePrice(domainKey)) {
    return 0;
  }

  return entityAccessCodesPrice || domainAccessCodesPrice || 3;
};

const ACCESS_CODE_DEFAULTS = {
  price: 3,
  maxResalePrice: 200,
  maxExpirationPeriod: 24,
  maxPurchaseAmount: 100,
  ignoreAccessCodePrice,
  getAccessCodePrice,
};

export default ACCESS_CODE_DEFAULTS;
