import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import InputMask from 'react-input-mask';

import { Controller, useForm } from 'react-hook-form';
import { useSetUserPositionMutation } from 'store/services/superAdmin/AdminUsers';
import Button from 'style-guide/Button';
import Form from 'style-guide/Form';
import { Input } from 'style-guide/Input';
import { message } from 'style-guide/ToastMessages';
import Text from 'style-guide/Typography/Text';
import schema from './schema';

const UserModifyModal = ({ hideModal, params: { id } }) => {
  const [userPosition, { isSuccess, isError }] = useSetUserPositionMutation();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      phone: '',
    },
  });

  const onFormSubmit = (data) => {
    userPosition({ id, isDomainSuperAdmin: true, isSuperAdmin: false, phoneNumber: data?.phone || null });
  };

  useEffect(() => {
    if (isSuccess) {
      hideModal();
      message.success('User successfully converted to sub admin');
    }
    if (isError) {
      message.error('Something went wrong!');
    }
  }, [isSuccess, isError, hideModal]);

  return (
    <div>
      <Form onSubmit={handleSubmit(onFormSubmit)}>
        <Text $variant={2} $dark={100}>
          Please provide phone number to enable MFA for sub-admin
        </Text>
        <Controller
          control={control}
          name='phone'
          render={({ field: { onChange, value } }) => (
            <InputMask mask='1(999)999-9999' value={value} onChange={onChange}>
              {(inputProps) => <Input error={errors.phone} {...inputProps} className='pv--16' />}
            </InputMask>
          )}
        />
        <div className='d-flex justify-content-center'>
          <Button variant='secondary' className='mr--24' onClick={() => hideModal()}>
            Cancel
          </Button>
          <Button variant='primary' type='submit'>
            Submit
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default UserModifyModal;
