import emptySplitApi from '..';

const adminCourseApi = emptySplitApi.injectEndpoints({
  endpoints: (build) => ({
    getDomains: build.query({
      query: (params) => ({
        url: `admin/domains`,
        method: 'GET',
        params,
      }),
    }),
    getDomain: build.query({
      query: (params) => ({
        url: `admin/domains/${params.id}`,
        method: 'GET',
      }),
    }),
    addCompany: build.mutation({
      query: (params) => ({
        url: `admin/domains`,
        method: 'POST',
        body: params,
      }),
      invalidatesTags: ['domain-tracking'],
    }),
    getSuperAdminCourses: build.query({
      query: (params) => ({
        url: `admin/courses`,
        method: 'GET',
        params,
      }),
      providesTags: ['admin-courses', 'isArchive'],
    }),
    joinUserCourses: build.mutation({
      query: (params) => ({
        url: `admin/courses`,
        method: 'POST',
        body: params,
      }),
      invalidatesTags: ['unjoined-courses'],
    }),
    deleteCourse: build.mutation({
      query: (params) => ({
        url: `admin/courses/${params.id}`,
        method: 'DELETE',
        params,
      }),
      invalidatesTags: ['admin-courses'],
    }),
    getUnjoinedCourses: build.query({
      query: (params) => ({
        url: `admin/courses/${params.id}`,
        method: 'GET',
        params,
      }),
      providesTags: ['unjoined-courses', 'isArchive'],
    }),
    updateDemo: build.mutation({
      query: (params) => ({
        url: `admin/courses/${params.id}`,
        method: 'PUT',
        body: params,
      }),
      invalidatesTags: ['admin-courses'],
    }),
    updateChapter: build.mutation({
      query: (params) => ({
        url: `admin/courses/${params.courseId}/volumes/${params.id}`,
        method: 'PUT',
        body: params,
      }),
      invalidatesTags: ['volumes'],
    }),
    getVolumes: build.query({
      query: (params) => ({
        url: `admin/courses/${params.id}/volumes`,
        method: 'GET',
        params,
      }),
      providesTags: ['volumes'],
    }),
  }),
});

export default adminCourseApi;
export const {
  useGetDomainQuery,
  useGetDomainsQuery,
  useGetVolumesQuery,
  useGetSuperAdminCoursesQuery,
  useDeleteCourseMutation,
  useUpdateDemoMutation,
  useGetUnjoinedCoursesQuery,
  useJoinUserCoursesMutation,
  useUpdateChapterMutation,
  useAddCompanyMutation,
} = adminCourseApi;
