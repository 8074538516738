import { Fragment, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import usePrevious from 'hooks/usePrevious';

import classNames from 'classnames';
import { loadState } from 'configs/localStorage';
import userMenu from 'constants/DropdownOptions/header';
import useLogout from 'store/services/helperHooks/useLogout';
import Menu, { MenuItem, SubMenu } from 'style-guide/Menu';
import RightSectionWrapper from './style/RightSectionWrapper';

const RightSection = ({ isOpen, close }) => {
  const navigate = useNavigate();

  const { pathname } = useLocation();
  const prevPathName = usePrevious(pathname);
  const [logOut] = useLogout();

  const profileSlice = useSelector((state) => state?.profileSlice);

  const { adminUser } = loadState();
  const isAuthSuperAdmin = adminUser?.accessToken;

  const { userData: user } = profileSlice;

  useEffect(() => {
    if (prevPathName !== undefined && prevPathName !== pathname) {
      close();
    }
  }, [pathname, prevPathName, close]);

  return (
    <RightSectionWrapper className={classNames({ 'd-flex justify-content-end': isOpen })}>
      <Menu mode='inline' className='right-menu  p--16 '>
        {isAuthSuperAdmin && (
          <MenuItem
            onClick={() => {
              navigate('/admin/users');
            }}
          >
            Admin Dashboard
          </MenuItem>
        )}
        {Object.keys(user).length > 0 &&
          userMenu(user).map((item) => (
            <Fragment key={item.name}>
              {item.submenu ? (
                <SubMenu title={item.name}>
                  {item.submenu.map(({ name: entityName, path }) => (
                    <MenuItem key={entityName} onClick={() => navigate(path)}>
                      {entityName}
                    </MenuItem>
                  ))}
                </SubMenu>
              ) : (
                <>
                  <MenuItem
                    onClick={() => {
                      navigate(item.path);
                    }}
                  >
                    {item.name}
                  </MenuItem>
                  {item.renderDivider ? <div className='divider' /> : null}
                </>
              )}
            </Fragment>
          ))}
        <MenuItem
          onClick={() => {
            logOut();
          }}
        >
          Log Out
        </MenuItem>
      </Menu>
    </RightSectionWrapper>
  );
};

export default RightSection;
