const domainFavicon = {
  peertree: { title: 'Peer Tree', link: '/favicon.ico' },
  boyens: { title: 'Boyens University', link: '/favicon-boyens.ico' },
  hippoCampus: { title: 'HippoCampus', link: '/favicon-hippo.ico' },
  politicalIslam: { title: 'Political Islam', link: '/favicon-political.ico' },
  vanderbilt: { title: 'Vanderbilt School of Nursing', link: '/favicon-vanderbilt.ico' },
  c2c: { title: 'Sales Coaching Campus', link: '/favicon-c2c.ico' },
  pear: { title: 'PEAR Core University', link: '/favicon-pear.ico' },
  hrights: { title: 'The MLK Social Responsibility Initiative', link: '/favicon-hrights.ico' },
  nucleus: { title: 'CC NUCLEUS', link: '/favicon-nucleus.ico' },
  fsc: { title: 'Freddie Scott', link: '/favicon-fsc.ico' },
  ecf: { title: 'Epic Challenge Foundation', link: '/favicon-ecf.ico' },
  csm: { title: 'CSM Academy', link: '/favicon-csm.ico' },
  vaco: { title: 'CSM Academy', link: '/favicon-csm.ico' },
  tvguest: { title: 'TVGuest', link: '/favicon-tvguest.ico' },
  me: { title: 'ME', link: '/favicon-me.ico' },
  workvibes: { title: 'WorkVibes', link: '/favicon-workvibes.ico' },
  ssa: { title: 'SSA', link: '/favicon-ssa.ico' },
  spaces: { title: 'e|spaces', link: '/favicon-spaces.ico' },
  em: { title: 'Entrepreneurial mind', link: '/favicon-mind.ico' },
  fba: { title: 'Freedom Business Alliance', link: '/favicon-fba.ico' },
  mh360: { title: 'SCOUT ', link: '/favicon-scout.ico' },
  saq: { title: 'SAQ', link: '/favicon-saq.ico' },
};

export default domainFavicon;
