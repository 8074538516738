import { string, object } from 'yup';

const schema = object({
  phone: string().test(
    'is-valid-phone',
    'Phone number must be in the format 1(XXX)XXX-XXXX',
    (value) => !value || /^1\(\d{3}\)\d{3}-\d{4}$/.test(value)
  ),
});

export default schema;
