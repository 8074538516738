import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { ENTITY_TYPES } from 'common';
import { message } from 'style-guide/ToastMessages';

import { useCreateAnswerMutation } from 'store/services/learningCommunities/Assessment';

import Button from 'style-guide/Button';
import Form from 'style-guide/Form';

import { isJSON } from 'utils/helperFunctions';
import LearnerCard from './AssessmentCards/LearnerCard';
import CompletedAssessments from './_partial/CompletedAssessments';
import MultipleBlanksCard from './_partial/MultipleBlanksCard';
import RetakeAssessment from './_partial/RetakeAssessment';
import schema from './schema';

const { LEARNING_COMMUNITY } = ENTITY_TYPES;

const LearnerPreview = ({ data, courseId, chapterId, testNumber }) => {
  const [createAnswer] = useCreateAnswerMutation();
  const { urlName, role, category } = useParams();
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();
  const mode = searchParams.get('mode');

  const categoryUrl = `/${LEARNING_COMMUNITY.key}/${courseId}/${urlName}/${role}/${chapterId}/${category}`;
  const answeredTest = data?.answeredTests?.find((el) => el.testNumber === +testNumber);
  const hasAnsweredTest = data?.answeredTests?.length;

  const {
    control,
    handleSubmit,
    setValue,
    formState: { isValid },
  } = useForm({
    defaultValues: { answers: [] },
    mode: 'onChange',
    resolver: yupResolver(schema),
  });
  const onFormSubmit = async (formData) => {
    const { data: createdData } = await createAnswer({ id: courseId, chapterId, result: formData });

    if (!createdData) {
      return message.error('Something went wrong');
    }

    const currentTestNumber = createdData?.answeredTests.at(-1).testNumber;

    const storedData = localStorage.getItem(String(chapterId));
    const parsedData = isJSON(storedData) && JSON.parse(storedData);

    if (storedData && createdData) {
      localStorage.setItem(
        String(chapterId),
        JSON.stringify({
          ...parsedData,
          answeredTests: [...parsedData.answeredTests, { ...createdData?.answeredTests.at(-1) }],
        })
      );
    }
    navigate(`${categoryUrl}/preview/${currentTestNumber}`);
  };

  useEffect(() => {
    if (hasAnsweredTest && !testNumber) {
      navigate(`${categoryUrl}/?mode=preview`, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasAnsweredTest]);
  return (
    <>
      {(hasAnsweredTest && mode === 'preview') || testNumber ? (
        <>
          <RetakeAssessment categoryUrl={categoryUrl} answeredTestsCount={data?.answeredTests?.length} />
          <CompletedAssessments
            assessments={data?.answeredTests}
            questions={data?.questions}
            control={control}
            chapterId={chapterId}
            courseId={courseId}
            setValue={setValue}
            navigate={navigate}
            categoryUrl={categoryUrl}
            testNumber={testNumber}
            answeredTest={answeredTest}
          />
        </>
      ) : null}
      {!mode && !testNumber ? (
        <Form onSubmit={handleSubmit(onFormSubmit)}>
          {data?.questions.map((el, index) =>
            el.type === 3 ? (
              <MultipleBlanksCard
                key={el.id}
                answer={el.answer}
                index={index}
                question={el.question}
                setValue={setValue}
                assessmentInfo={el}
                answeredTest={answeredTest}
              />
            ) : (
              <LearnerCard
                key={el.id}
                index={index}
                assessmentInfo={el}
                control={control}
                chapterId={chapterId}
                courseId={courseId}
                setValue={setValue}
                answeredTest={answeredTest}
              />
            )
          )}
          <div className='preview-buttons'>
            <Button variant='primary' type='submit' disabled={!isValid}>
              Submit
            </Button>
          </div>
        </Form>
      ) : null}
    </>
  );
};

export default LearnerPreview;
