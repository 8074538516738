import { ENTITY_TYPES } from 'common';
import { loadState, saveLocalStorage } from 'configs/localStorage';
import MODAL_TYPES from 'constants/modals';
import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  useDeleteAdminUserMutation,
  useLazyGetAdminDataQuery,
  useReActivateAdminUserMutation,
  useSetUserPositionMutation,
} from 'store/services/superAdmin/AdminUsers';
import { showModal } from 'store/slices/modal';
import { changeUserData } from 'store/slices/profile';
import Button from 'style-guide/Button';
import Dropdown from 'style-guide/DropdownV2';
import Menu, { MenuItem } from 'style-guide/Menu';
import { message } from 'style-guide/ToastMessages';
import Text from 'style-guide/Typography/Text';
import Title from 'style-guide/Typography/Title';

const coursePath = (item) => `/user/${item.userName}`;
const { LEARNING_COMMUNITY } = ENTITY_TYPES;

const Actions = ({ item }) => {
  const dispatch = useDispatch();
  const [deleteUser] = useDeleteAdminUserMutation();
  const [reActivateUser] = useReActivateAdminUserMutation();
  const [getAdminData] = useLazyGetAdminDataQuery();
  const [userPosition, { isSuccess, isError }] = useSetUserPositionMutation();
  const navigate = useNavigate();
  const isDeletedUser = item?.deletedAt;
  const { adminUser } = loadState();

  const isDomainSuperAdmin = adminUser?.isDomainSuperAdmin;

  const submitDelete = useMemo(
    () => ({
      title: `Are you sure you want to ${isDeletedUser ? 'reactivate' : 'remove'}  the user?`,
      submit: () =>
        isDeletedUser ? reActivateUser({ id: item.id, status: 'reactivate' }) : deleteUser({ id: item.id }),
    }),
    [deleteUser, isDeletedUser, item.id, reActivateUser]
  );
  const submitPosition = {
    title: `Are you sure you want to remove ${item.userName} from sub-admin list?`,
    submit: () => userPosition({ id: item.id, isDomainSuperAdmin: false, isSuperAdmin: false, phoneNumber: null }),
  };

  const setUserData = async (e, setPath) => {
    await getAdminData({ id: e.id }).then((data) => {
      saveLocalStorage({
        user: data?.data,
      });
      ``;
      dispatch(changeUserData({ isAuth: true, userData: data?.data }));
      navigate(setPath(e));
    });
  };

  const showConfirmationModal = ({ title, submit }) => {
    dispatch(
      showModal({
        type: MODAL_TYPES.CONFIRMATION,
        params: {
          modal: {
            title: <Title>Confirmation</Title>,
          },
          body: {
            title: <Text $variant={2}>{title}</Text>,
            onOk: submit,
          },
        },
      })
    );
  };

  const showModifyModal = () => {
    dispatch(
      showModal({
        type: MODAL_TYPES.USER_MODIFY,
        params: {
          modal: {
            title: <Title>Convert user to Domainadmin?</Title>,
          },
          body: {
            title: <Text $variant={2}>Please provide phone number to enable MFA for sub-admin</Text>,
            id: item.id,
          },
        },
      })
    );
  };

  const showJoinModal = () => {
    dispatch(
      showModal({
        type: MODAL_TYPES.JOIN_USER_TO_COURSES,
        params: {
          modal: {
            title: <Title variant={5}>Please choose a {LEARNING_COMMUNITY.singular} for the Member </Title>,
          },

          body: {
            id: item.id,
          },
        },
      })
    );
  };

  useEffect(() => {
    if (isSuccess) {
      message.success('User successfully removed from admin list');
    }
    if (isError) {
      message.error('Something went wrong!');
    }
  }, [isSuccess, isError]);

  return (
    <div className='d-flex align-items-center'>
      <Dropdown
        trigger='click'
        placement='bottomLeft'
        className='role-dropdown'
        overlay={
          <Menu>
            {!isDeletedUser && (
              <MenuItem key='view-user' onClick={() => setUserData(item, coursePath)}>
                View User
              </MenuItem>
            )}

            <MenuItem key='join-to' onClick={showJoinModal}>
              Join user to Community
            </MenuItem>
            {!isDomainSuperAdmin && (
              <MenuItem
                key='upgrade'
                onClick={() => (item?.isDomainSuperAdmin ? showConfirmationModal(submitPosition) : showModifyModal())}
              >
                {item?.isDomainSuperAdmin ? 'Downgrade Users' : 'Upgrade user'}
              </MenuItem>
            )}
            <MenuItem onClick={() => showConfirmationModal(submitDelete)} key='enable-user'>
              {item?.deletedAt ? 'Reactivate user' : 'Remove user'}
            </MenuItem>
          </Menu>
        }
      >
        <Button variant='link'>...</Button>
      </Dropdown>
    </div>
  );
};

export default Actions;
