import { getDomainTracking, saveLocalStorage } from 'configs/localStorage';
import PATHS from 'constants/paths';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { resetApiState, upsertQueryData } from 'store/redux-toolkit-common';
import { isAuth } from 'store/slices/authorizations';
import { changeUserData } from 'store/slices/profile';
import { useLogOutMutation } from '../users';

const useLogout = (path = PATHS.HOME) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [logOut, result, ...params] = useLogOutMutation();

  const logOutWithRedirect = () => {
    logOut().then(({ data }) => {
      if (data?.accessToken) {
        Object.keys(localStorage).forEach((key) => {
          if (!Number.isNaN(Number(key))) {
            localStorage.removeItem(key);
          }
        });

        saveLocalStorage({
          adminUser: null,
          user: null,
        });
        dispatch(
          isAuth({
            isAuth: false,
            userData: { user: null, adminUser: null },
          })
        );
        dispatch(changeUserData({ userData: {} }));
        dispatch(resetApiState());
        const domainTrackingData = getDomainTracking();
        dispatch(upsertQueryData('domainTracking', undefined, domainTrackingData));
        navigate(path);
      }
    });
  };

  return [logOutWithRedirect, result, ...params];
};

export default useLogout;
