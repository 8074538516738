import AppRouterLayout from 'Components/App/AppRouterLayout';
import PATHS from 'constants/paths';
import { lazy } from 'react';
import { Navigate, generatePath } from 'react-router-dom';

import NotificationsSettings from 'views/Notifications/NotificationSettings';
import entitiesCreatePageRoutes from '../entityPagesRoute/entitiesCreatePageRoutes';
import entitiesHomepageRoutes from '../entityPagesRoute/entitiesHomepageRoutes';

import entitiesPageRoutes from '../entityPagesRoute/entitiesPageRoutes';
import redirectRoutes from '../redirectRoutes';
import staticPageRoutes from '../staticPageRoutes';
import superAdminPaths from '../superAdminPaths';

const Review = lazy(() => import('views/Dashboard/Submissions/Review'));
const Notifications = lazy(() => import('views/Notifications'));
const Profile = lazy(() => import('views/Profile'));
const Dashboard = lazy(() => import('views/Dashboard'));
const CollectionsMemberDashboard = lazy(() => import('views/Member/MyCollections'));
const OrganizationsMemberDashboard = lazy(() => import('views/Member/MyOrganizations'));
const LearningCommunitiesMemberDashboard = lazy(() => import('views/Member/MyLearningCommunities'));
const ExploreLearningCommunities = lazy(() => import('views/NewExplore/LearningCommunities'));
const ExploreCollections = lazy(() => import('views/NewExplore/Collections'));
const ExploreOrganizations = lazy(() => import('views/NewExplore/Organizations'));
const LearningCommunityInvitations = lazy(() => import('views/Member/MyInvitations'));

const limitedUserRoute = (user, adminUser) => [
  {
    path: '/',
    element: <AppRouterLayout />,
    children: [
      {
        index: true,
        element: (
          <Navigate
            to={generatePath(user?.userName ? PATHS.PROFILE : PATHS.SIGN_IN, { userName: user.userName })}
            replace
          />
        ),
      },
      {
        element: <Profile />,
        path: PATHS.PROFILE,
      },
      ...staticPageRoutes,
      {
        path: PATHS.EXPLORE_LEARNING_COMMUNITIES,
        element: <ExploreLearningCommunities />,
      },
      {
        path: PATHS.EXPLORE_COLLECTIONS,
        element: <ExploreCollections />,
      },
      {
        path: PATHS.EXPLORE_ORGANIZATIONS,
        element: <ExploreOrganizations />,
      },
      {
        element: <LearningCommunitiesMemberDashboard />,
        path: PATHS.LEARNING_COMMUNITIES_MEMBER_DASHBOARD,
      },
      {
        element: <CollectionsMemberDashboard />,
        path: PATHS.COLLECTIONS_MEMBER_DASHBOARD,
      },
      {
        element: <OrganizationsMemberDashboard />,
        path: PATHS.ORGANIZATIONS_MEMBER_DASHBOARD,
      },
      {
        element: <LearningCommunityInvitations />,
        path: PATHS.LEARNING_COMMUNITIES_INVITATIONS_DASHBOARD,
      },
      ...entitiesPageRoutes,
      ...entitiesHomepageRoutes,
      ...entitiesCreatePageRoutes,
      ...superAdminPaths(adminUser),
      { element: <NotificationsSettings />, path: PATHS.NOTIFICATIONS_SETTINGS_PAGE },
      {
        element: <Review />,
        path: PATHS.SUBMISSION_REVIEW,
      },
      { index: true, element: <Notifications />, path: PATHS.NOTIFICATIONS },
      {
        path: PATHS.DASHBOARD,
        element: <Dashboard />,
      },
    ],
  },
  ...redirectRoutes,
  {
    path: '/*',
    element: <Navigate to={generatePath(PATHS.PROFILE, { userName: user.userName })} replace />,
  },
];

export default limitedUserRoute;
